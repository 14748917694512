import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './get-started-hero.css';


const GetStartedHero: React.FC = () => {
    return (
        <StaticQuery
            query={graphql`
                {
                    mobileBgImage: file(relativePath: { eq: "get-started/getstarted_bgshapes_img.png" }) {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE)
                        }
                    }
                }
            `}
            render={() => (
                <section className="get-started-page">
                    <div style={{ position: 'relative', zIndex: 2 }}>
                    <div className="container">
                                <div className="get-started-hero-text-wrap">
                                    <h1 className="semi-bold text-h2">Experience AptEdge AnswerGPT&#8482; today</h1>
                                    <p className="dark-gray text-md-new">
                                        Turn disparate knowledge systems into faster case resolution, improved customer satisfaction, and lower customer support cost.

                                    </p>
                                    <div className='list-wrap'>
                                        <p>Why choose AptEdge?</p>
                                        <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                                            <li>
                                                Prevent Escalations
                                            </li>
                                            <li>
                                                Reduce Ticket Volume
                                            </li>
                                            <li>
                                                Improve Resolution Time
                                            </li>
                                            <li>
                                                Improve CSAT
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="get-started-form-wrap">
                                    <div className='form-header'>
                                        <p className='title'>Schedule a Demo</p>
                                    </div>
                                    <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D4P000000hMNv" method="POST">
                                        <input type="hidden" name="oid" value="00D4P000000hMNv"/>
                                        <input type="hidden" name="retURL" value="https://aptedge.io" />
                                        <input type="hidden" id="lead_source" name="lead_source" value="Web" />
                                        <input type="hidden" id="00NVW000000H4lZ" name="00NVW000000H4lZ" value="Schedule a Demo Form" />
                                        <label htmlFor="first_name">First Name*</label><input id="first_name" maxLength={40} name="first_name" size={20} type="text" required /><br/>
                                        <label htmlFor="last_name">Last Name*</label><input id="last_name" maxLength={80} name="last_name" size={20} type="text" required /><br/>
                                        <label htmlFor="email">Email*</label><input id="email" maxLength={80} name="email" size={20} type="email" required/><br/>
                                        <label htmlFor="phone">Phone</label><input id="phone" maxLength={40} name="phone" size={20} type="text" /><br/>
                                        <label htmlFor="company">Company*</label><input id="company" maxLength={40} name="company" size={20} type="text" required /><br/>
                                        <label htmlFor="title">Title</label><input id="title" maxLength={40} name="title" size={20} type="text" /><br/>
                                        <label htmlFor="00NVW000001Z4OL">Ticketing System</label><input id="00NVW000001Z4OL" maxLength={250} name="00NVW000001Z4OL" size={20} type="text" /><br/>
                                        <button type="submit" name="submit" className="app-btn">Schedule Now</button>
                                    </form>
                                </div>
                            </div>
                    </div>
                </section>
            )}
        />
    );
};

export default GetStartedHero;